import React from "react";

import AppLayout from "../../../components/shared/AppLayout";

const PageTermsAndConditions01 = () => {
    return (
        <AppLayout hasHeader={true} isBackBtn={true} hasFooter={true}>
            <div className="app-page page-terms-and-conditions">

                <div className="terms-and-conditions">

                    <div className="container">

                        <p className="terms-and-conditions__title">
                            <strong>履行個人資料保護法告知義務</strong>
                        </p>

                        <div className="terms-and-conditions__wrapper">
                            <p>保特保險經紀人股份有限公司（以下簡稱本公司）依據個人資料保護法（以下簡稱個資法）第八 條第一項（如為間接蒐集之個人資料則為第九條第一項）規定，向 台端告知下列事項，請 台端 詳閱：</p>
                            <p>蒐集之目的：財產保險（093）。</p>
                            <p>人身保險（001）。</p>
                            <p>行銷（040 ）。</p>
                            <p>消費者、客戶管理與服務（090）。</p>
                            <p>廣告或商業行為管理( 152) 。</p>
                            <p>其他經營合於營業登記項目或組織章程所定之業務（181 ）。</p>
                            <p>二.蒐集之個人資料類別：識別類、特徵類、家庭情形、社會情況、教育程度及其他專業、受僱情形、財務細節等，包含要保人、被保險人、受益人、或責任險第三人之姓名、身分證字號、出生年月日、護照號碼、婚姻、家庭、教育、職業、病歷、醫療、健康檢查、聯絡方式、財務狀況等資料及其他得以直接或間接方式識別該個人之資料。</p>
                            <p>三. 個人資料之來源（個人資料非由當事人提供間接蒐集之情形適用）<br/>(一)要保人∕被保險人。<br/>(二)司法警憲機關、委託協助處理理賠之公證人或機構。<br/>(三)當事人之法定代理人、輔助人。<br/>(四)各醫療院所。<br/>(五) 與第三人共同行銷、交互運用客戶資料、合作推廣等關係、或於本公司各項業務內所委託 往來之第三人。</p>
                            <p>四. 個人資料利用之期間、對象、地區、方式<br/>(一) 期間：因執行業務所必須及依法令規定應為保存之期間。<br/>(二) 對象：本（分）公司、中華民國產物保險商業同業公會、中華民國人壽保險商業同業公會、財團法人保險事業發展中心、財團法人保險安定基金、財團法人住宅地震保險基金、中華民國保險經紀人公會、財團法人汽車交通事故特別補償基金、財團法人金融消費評議中心、財團法人金融聯合徵信中心、財團法人聯合信用卡中心、台灣票據交換所、財金資訊公司、關貿網路股份有限公司、中央健康保險局、業務委外機構、與本公司有合作推廣或共同行銷之公司、依法有調查權機關或金融監理機關 。<br/>(三)地區：上述對象所在之地區及因辦理保險相關與其他附屬業務包括但不限於資訊蒐集、傳輸、儲存所需要之第三方地區或國家。<br/>(四) 方式：合於法令規定之利用方式。</p>
                            <p>五. 依據個資法第三條規定， 台端就本公司保有 台端之個人資料得行使之權利及方式：<br/>(一) 得向本公司行使之權利：<br/>1. 向本公司查詢、請求閱覽或請求製給複製本<br/>2.向本公司請求補充或更正。<br/>3. 向本公司請求停止蒐集、處理或利用或請求刪除。<br/>(二) 行使權利之方式：以書面（包含紙本及電子郵件）或致電本公司 （電話：0809-010-272)。</p>
                            <p>六.台端不提供個人資料所致權益之影響（個人資料由當事人直接蒐集之情形適用）：台端若未能提供相關個人資料時，本公司將可能延後或無法進行必要之審核及處理作業，因此可能遲延或無法提供台端相關服務或給付，保險公司可能婉謝承保。</p>
                        </div>

                    </div>

                </div>

            </div>
        </AppLayout>
    );
};

export default PageTermsAndConditions01;
